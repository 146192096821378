@import "color-palette";
@import "vars";
@import "breakpoints";

.company {
    display: flex;
    flex-direction: column;

    &__title {
        font-size: 18px;
        line-height: 22px;
        font-weight: $font-weight-bold;
        padding: 0;
        margin: 0 0 16px;
    }

    &__wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 16px;
        margin-bottom: 20px;
    }

    &__input {
        min-width: 300px;

        &--error {
            color: $color-text-red;
            font-size: 12px;
            line-height: 14px;
        }
    }

    &__label {
        font-size: 13px;
        line-height: 16px;
        font-weight: $font-weight-medium;
        margin-bottom: 5px;
    }

    &__status {
        margin-top: 16px;
        font-size: 13px;
        line-height: 16px;
        font-weight: $font-weight-medium;
        display: flex;
        align-items: center;
        gap: 6px;
        width: fit-content;
        background-color: $color-bg-blue-light;
        border-radius: $border-radius-6;
        padding: 4.5px 6px;
    }

    &__divider {
        border-top: 0.5px solid $color-divider-border;
        margin: 20px 0;
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 10px;
    }

    &__save,
    &__cancel {
        min-width: 250px;
        padding: 17px 10px;
        border-radius: $border-radius-10;
        border: none;
        font-size: 13px;
        line-height: 16px;
        font-weight: $font-weight-semibold;
        cursor: pointer;

        @media (max-width: $breakpoint-tablet-xs) {
            min-width: 100%;
        }
    }

    &__save {
        background-color: $color-bg-blue;
        color: $color-text-white;
    }

    &__cancel {
        border: 1px solid $color-divider-border;
        color: $color-text-black-green;
    }
}