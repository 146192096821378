@import "color-palette";
@import "vars";

.TextInput {
    input {
        height: 36px;
        padding: 10px 13px;
        border: 1px solid $color-divider-border;
        border-radius: $border-radius-6;
        font-weight: $font-weight-medium;
        font-size: 13px;
        line-height: 16px;
        color: $color-text-black;
        width: 100%;
    }
}